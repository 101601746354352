// import React, { useEffect } from 'react';

// class DiplomaPage extends React.Component {


//   componentDidMount() {
//     const isClient = typeof window === "object"

//     // if (isBrowser) {
//     //   const search = window.location.search;
//     //   const query = new URLSearchParams(search);
//     //   const codigoValidacao = query.get('CodigoValidacao');
//     //   console.log(codigoValidacao);
//     //   console.log('window is ready');
//     //   console.log(window);
//     // }
//     useEffect(() => {
//       if (isClient()) {
//         window.location.replace('https://google.com');
//       }
//     }, []);
//   }

//   render() {
//     console.log('Render lifecycle')
//     return <h1>Hello</h1>;
//   }
// }

// export default DiplomaPage;
import React, { useState, useEffect } from 'react';
import { useQueryParamString } from 'react-use-query-param-string';



function useCounter() {
  const [searchText, setSearchText, initialized] = useQueryParamString('codigovalidacao', '');
  useEffect(() => {
    window.location.replace(`https://dd.diplomax.cloud/?codigovalidacao=${searchText}`);
  }, [initialized]);
  console.log(searchText);
}
// function useCounter() {
//   const isClient = typeof window === "object"
//   const [count, setCount] = useState(0);
//     useEffect(() => {
//       if (isClient) {
//         const search = window.location.search;
//         const query = new URLSearchParams(search);
//         const codigovalidacao = query.get('codigovalidacao');
//         window.location.replace(`https://dd.diplomax.cloud/?codigovalidacao=${codigovalidacao}`);
//       }
//     }, [window.location.search]);
// }

export default function DiplomasPage(props) {
  useCounter();
  return <></>
}